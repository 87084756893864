<template>
  <!--begin::Card-->
  <div class="card card-flush pt-3 mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2 class="fw-bolder me-6">Event Details</h2>
        <a @click="$emit('edit')" class="btn btn-light btn-sm"
          ><i class="bi bi-pencil-square me-1 mb-1"></i>Edit</a
        >
      </div>
      <!--begin::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-3">
      <!--begin::Overview Section-->
      <div class="mb-0">
        <!--begin::Title-->
        <h5 class="mb-4">Overview:</h5>
        <!--end::Title-->
        <!--begin::Patient-->
        <div class="d-flex flex-wrap py-5">
          <!--begin::Column-->
          <div class="flex-equal me-5">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Date:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ new Date(operation.start).toLocaleDateString() }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Start:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ new Date(operation.start).toLocaleTimeString() }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Assigned doctor:</td>
                <td class="text-gray-800">
                  Dr {{ operation.doctor.firstname }}
                  {{ operation.doctor.lastname }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Color(s):</td>
                <td class="text-gray-800">
                  {{ operation.products.map((e) => e.name).join(", ") }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Down payment:</td>
                <td class="text-gray-800">
                  {{ operation.downPayment ? "Paid" : "Waiting for payment" }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Location:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ operation.location }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Duration:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ operation.duration.hours }}hr
                  {{ operation.duration.minutes }}mins
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Type:</td>
                <td class="min-w-200px">
                  <div class="d-inline-block p-1" :class="`bg-${getTypeBadgeColorClass(operation.type)}`">
                    <p
                      class="badge mb-0"
                      :class="`badge-${getTypeBadgeColorClass(operation.type)}`"
                    >
                      <i
                        :class="`${getTypeIcon(
                          operation.type
                        )} text-${getTypeColorClass(operation.type)}`"
                      ></i>
                      {{ operation.type }}
                    </p>
                  </div>
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Density:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ operation.density }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Patient-->
      </div>
      <!--end::Overview Section-->
      <div class="separator mb-10 mt-4"></div>
      <!--begin::Patient Section-->
      <div v-if="operation.patient" class="mb-10">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Patient:</h5>
          <!--end::Title-->
          <router-link
            class="btn btn-sm btn-light"
            :to="{ name: 'patient', params: { id: operation.patient.id } }"
            ><i class="bi bi-box-arrow-up-right me-1 mb-1"></i>Patient's
            profile</router-link
          >
        </div>

        <!--begin::Patient-->
        <div class="d-flex flex-wrap pt-5">
          <!--begin::Column-->
          <div class="flex-equal me-">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Name:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ operation.patient.firstname }}
                  {{ operation.patient.lastname }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Civil:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ operation.patient.sex }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Email:</td>
                <td class="text-gray-800">{{ operation.patient.email }}</td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Country:</td>
                <td class="text-gray-800">
                  {{ operation.patient.address.country }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Patient-->
      </div>
      <!--end::Patient Section-->
      <div class="separator mb-12"></div>
      <!--begin::Products Section-->
      <div class="mb-10">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-4">Products:</h5>
          <!--end::Title-->
          <router-link class="btn btn-sm btn-light" to="/marketplace">
            <i class="bi bi-box-arrow-up-right me-1 mb-1"></i>
            Marketplace
          </router-link>
        </div>

        <!--begin::Patient-->
        <!--begin::Product table-->
        <div class="table-responsive">
          <!--begin::Table-->
          <table class="table align-middle table-row-dashed fs-6 gy-4 mb-0">
            <!--begin::Table head-->
            <thead>
              <!--begin::Table row-->
              <tr
                class="
                  border-bottom border-gray-200
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
              >
                <th class="min-w-150px">Category</th>
                <th class="min-w-150px">Product</th>
                <th class="min-w-125px">In stock</th>
              </tr>
              <!--end::Table row-->
            </thead>
            <!--end::Table head-->

            <!--begin::Table body-->
            <tbody class="fw-bold text-gray-800">
              <tr v-for="(product, idx) in operation.products" :key="idx">
                <td>
                  <label class="w-150px">{{ product.category }}</label>
                </td>
                <td>
                  <label class="w-150px">{{ product.name }}</label>
                  <!-- <div class="fw-normal text-gray-600">{{ product.category }}</div> -->
                </td>
                <td>
                  <span
                    v-if="isInStock(product)"
                    class="badge badge-light-success bg-light-success"
                  >
                    <i class="bi-check-lg text-success"></i>
                  </span>
                  <span v-else class="badge badge-light-danger bg-light-danger">
                    <i class="bi-x-lg text-danger"></i>
                  </span>
                </td>
              </tr>
            </tbody>
            <!--end::Table body-->
          </table>
          <!--end::Table-->
        </div>
        <!--end::Product table-->
      </div>
      <!--end::Products Section-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import UserMenu from "@/layout/header/partials/UserMenu.vue";
import ApiService from "@/core/services/ApiService";

import EventType from "@/core/constants/EventType.ts";

export default {
  props: {
    operation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stocks: [],
    };
  },
  created() {
    ApiService.query("/stocks", { params: { limit: 0 } }).then(({ data }) => {
      this.stocks = data.results;
    });
  },
  methods: {
    toggleEditDetails() {
      this.isEditingDetails = !this.isEditingDetails;
    },
    isInStock(product) {
      const stock = this.stocks.find((stock) => stock.product == product.id);
      if (!stock) return false;

      return Boolean(stock.quantity);
    },
    getTypeBadgeColorClass(type) {
      return EventType[type].badgeColorClass;
    },
    getTypeColorClass(type) {
      return EventType[type].colorClass;
    },
    getTypeIcon(type) {
      return EventType[type].icon;
    },
  },
};
</script>

<style lang="scss" scoped>
// .operation-type {
//   font-size: 0.9rem;
// }
</style>
