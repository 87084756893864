<template>
  <!--begin::Card-->
  <div
    class="card card-flush mb-0"
    data-kt-sticky="true"
    data-kt-sticky-name="subscription-summary"
    data-kt-sticky-offset="{default: false, lg: '200px'}"
    data-kt-sticky-width="{lg: '250px', xl: '300px'}"
    data-kt-sticky-left="auto"
    data-kt-sticky-top="150px"
    data-kt-sticky-animation="false"
    data-kt-sticky-zindex="95"
  >
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2>Summary</h2>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0 fs-6">
      <!--begin::Seperator-->
      <div class="separator separator-dashed mb-7"></div>
      <!--end::Seperator-->

      <!--begin::Section-->
      <div class="mb-7">
        <!--begin::Patient-->
        <h5 class="mb-4">
          {{
            operation.patient
              ? `${operation.patient.firstname} ${operation.patient.lastname}`
              : ""
          }}
        </h5>
        <!--end::Patient-->

        <!--begin::Type-->
        <p
          class="badge mb-2"
          :class="`badge-${getTypeBadgeColorClass(operation.type)}`"
        >
          <i
            :class="`${getTypeIcon(operation.type)} text-${getTypeColorClass(
              operation.type
            )}`"
          ></i>
          {{ operation.type }}
        </p>
        <!--end::Type-->

        <!--begin::Doctor-->
        <p class="fw-bold text-gray-600 mb-2">
          Dr {{ operation.doctor.firstname }} {{ operation.doctor.lastname }}
        </p>
        <!--end::Doctor-->
        <!--begin::Date-->
        <p class="fw-bold text-gray-600 mb-2">
          {{ new Date(operation.start).toLocaleDateString() }}
        </p>
        <!--end::Date-->
        <!--begin::Time-->
        <p class="fw-bold text-gray-600 mb-0">
          {{ new Date(operation.start).toLocaleTimeString() }}
        </p>
        <!--end::Time-->

        <!--end::Details-->
      </div>
      <!--end::Section-->

      <!--begin::Seperator-->
      <div class="separator separator-dashed mb-7"></div>
      <!--end::Seperator-->

      <!--begin::Section-->
      <div class="mb-4">
        <!--begin::Title-->
        <h5 class="mb-4">Actions</h5>
        <!--end::Title-->

        <!--begin::Details-->
        <div class="mb-0 d-grid gap-2">
          <template v-if="false">
            <a class="btn btn-light" @click.prevent="generateQuotation"
              >Generate quotation</a
            >
            <a class="btn btn-light" @click.prevent="generateBill"
              >Generate bill</a
            >
          </template>
          <div class="separator separator-dashed my-2"></div>
          <a class="btn btn-light" @click.prevent="seeInCalendar">
            <i class="bi bi-box-arrow-up-right me-1 mb-1" />
            See in calendar
          </a>
          <a v-if="false" class="btn btn-light">
            <i class="bi bi-box-arrow-up-right me-1 mb-1" />
            Send by email
          </a>
        </div>
        <!--end::Details-->
      </div>
      <!--end::Section-->

      <!--begin::Seperator-->
      <div class="separator separator-dashed mb-4"></div>
      <!--end::Seperator-->

      <!--begin::Actions-->
      <div class="mb-0 d-grid">
        <a @click="cancelEvent" class="btn btn-light-danger"> Cancel event </a>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import EventType from "@/core/constants/EventType.ts";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  props: {
    operation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    cancelEvent() {
      Swal.fire({
        text: `Delete this event?`,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-primary",
        },
      }).then(({ isConfirmed }) => {
        if (!isConfirmed) return;
        ApiService.delete(`operations/${this.operation.id}`).then(() => {
          Swal.fire({ text: `Event deleted`, icon: "success" });
          this.$router.push("/operations");
        });
      });
    },
    generateQuotation() {
      alert("In construction");
    },
    generateBill() {
      alert("In construction");
    },
    seeInCalendar() {
      this.$router.push({
        name: "calendar",
        query: { date: this.operation.start },
      });
    },
    getTypeBadgeColorClass(type) {
      return EventType[type].badgeColorClass;
    },
    getTypeColorClass(type) {
      return EventType[type].colorClass;
    },
    getTypeIcon(type) {
      return EventType[type].icon;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  border: 1px solid #dddee4 !important;
}
</style>
