<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    @beforeOpen="(event) => processParams(event.ref.params._rawValue)"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title">
        <slot name="title"></slot>
      </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <h5 class="fw-bolder mb-6">Event Details</h5>
      <form @submit.prevent="saveOperation" ref="form">
        <div class="d-flex flex-row row mb-6">
          <!-- Begin::Left col -->
          <div class="d-flex flex-column col-6 mb-5">
            <label for="date">Date</label>
            <input
              type="datetime-local"
              class="form-control"
              id="date"
              v-model="operation.start"
              required
            />
          </div>
          <!-- <div class="d-flex flex-column col-6 mb-5">
            <label for="location">Location</label>
            <input
              type="text"
              class="form-control"
              id="location"
              v-model="operation.location"
              required
            />
          </div> -->
          <div class="d-flex flex-column col-3 mb-5">
            <label for="hours">Duration (Hours)</label>
            <input
              type="number"
              class="form-control"
              id="hours"
              v-model="operation.duration.hours"
              required
            />
          </div>
          <div class="d-flex flex-column col-3 mb-5">
            <label for="minutes">Duration (Minutes)</label>
            <input
              type="number"
              class="form-control"
              id="minutes"
              v-model="operation.duration.minutes"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label>Assigned Doctor</label>
            <v-select
              v-model="operation.doctor"
              :options="doctors"
              :reduce="(e) => e.id"
              required
            >
              <template #no-options> Search by first/lastname... </template>
              <template #option="option">
                <div class="d-center">
                  {{ option.firstname }} {{ option.lastname }}
                </div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">
                  {{ option.firstname }} {{ option.lastname }}
                </div>
              </template>
            </v-select>
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="density">Density</label>
            <input
              type="number"
              min="1"
              max="5"
              step="1"
              class="form-control"
              id="density"
              v-model="operation.density"
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label>Type</label>
						<select v-model="operation.type" class="form-select" required>
              <option v-for="(type, idx) in Object.keys(eventTypes)" :key="`type-${idx}`" :value="type">
                {{ eventTypes[type].label }}
              </option>
            </select>
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label>Down payment</label>
            <div
              class="
                form-check form-switch form-check-custom form-check-solid
                mt-2
              "
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="downpayment"
                v-model="operation.downPayment"
              />
              <label class="form-check-label" for="downpayment">Paid</label>
            </div>
          </div>
          <!-- End::Right col -->
        </div>
        <!-- End::Details section -->
        <div class="d-flex flex-row row">
          <!-- Begin::Patient section -->
          <div class="d-flex flex-column col-6 mb-5">
            <h5 class="fw-bolder mb-6">Patient</h5>
            <v-select
              v-model="operation.patient"
              label="email"
              :filterable="false"
              :options="patients"
              @search="onSearch"
              :reduce="(e) => e.id"
              required
            >
              <template #no-options>
                Search by first/lastname or email address...
              </template>
              <template #option="option">
                <div class="d-center">
                  {{ option.firstname }} {{ option.lastname }} ({{
                    option.email
                  }})
                </div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">
                  {{ option.firstname }} {{ option.lastname }} ({{
                    option.email
                  }})
                </div>
              </template>
            </v-select>
          </div>
          <!-- End::Details section -->
          <!-- Begin::Products section -->
          <div class="d-flex flex-column col-6 mb-5">
            <h5 class="fw-bolder mb-6">Products</h5>
            <v-select
              v-if="products.length"
              label="name"
              v-model="operation.products"
              :options="products"
              :multiple="true"
              :reduce="(e) => e.id"
            >
              <template #no-options> Search by product name... </template>
              <template #option="option">
                <div class="d-center">
                  {{ option.name }}
                </div>
              </template>
              <template #selected-option="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
          </div>
          <!-- End::Details section -->
        </div>
      </form>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', operation)"
        >Confirm</a
      > -->
      <a class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm"
        >Confirm</a
      >
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import _ from "lodash";

import EventType from "@/core/constants/EventType";
import ApiService from "@/core/services/ApiService";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  emits: ["confirm", "cancel"],
  components: {
    vSelect,
  },
  data() {
    return {
      doctors: [],
      patients: [],
      products: [],
      operation: {
        doctor: null,
        patient: null,
        date: "",
        start: "",
        duration: {
          minutes: "",
          hours: "",
        },
        // location: "",
        type: "",
        color: "",
        density: null,
        downPayment: false,
        products: [],
      },
    };
  },
  mounted() {
    ApiService.query("/users", { params: { isDoctor: true } }).then(
      ({ data }) => {
        this.doctors = data.results;
        // Default doctor for new operation
        if (this.doctors.length == 1 && !this.operation.id) {
          this.operation.doctor = this.doctors[0].id;
        }
      }
    );

    ApiService.query("/products", { params: { limit: 0 } }).then(({ data }) => {
      this.products = data.results;
    });
  },
  computed: {
    eventTypes() {
      return EventType;
    }
  },
  methods: {
    onClickConfirm() {
      this.$refs.form.requestSubmit();
    },
    saveOperation() {
      this.operation.start = new Date(this.operation.start);
      console.log(JSON.parse(JSON.stringify(this.operation)));

      if (this.operation.id) {
        const config = {
          responseToast: {
            text: "Event updated successfully."
          }
        }

        ApiService.update("/operations", this.operation.id, this.operation, config)
          .then(({ data }) => {
            console.log(data);
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const config = {
          responseToast: {
            text: "Event created successfully."
          }
        }

        ApiService.post("/operations", this.operation, config)
          .then(({ data }) => {
            console.log(data);
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    processParams(existingOperation) {
      if (existingOperation) {
        // Convert to local time
        if (existingOperation.start) {
          const dt = new Date(existingOperation.start);
          dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
          existingOperation.start = dt.toISOString().slice(0, 16);
        }

        // Populate & reduce

        // Patient
        if (existingOperation.patient) {
          this.patients = [existingOperation.patient];
          existingOperation.patient = existingOperation.patient.id;
        }

        // Doctor
        if (existingOperation.doctor) {
          existingOperation.doctor = existingOperation.doctor.id;
        }

        // Products
        if (existingOperation.products) {
          existingOperation.products = existingOperation.products.map(
            (e) => e.id
          );
        }

        this.operation = Object.assign(
          this.operation,
          _.cloneDeep(existingOperation)
        );

        console.log(this.operation);
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      ApiService.query("/patients", {
        params: {
          firstname_contains: search,
          lastname_contains: search,
          email_contains: search,
          _or: 1,
        },
      })
        .then((res) => {
          vm.patients = res.data.results;
          loading(false);
        })
        .catch((err) => {
          vm.patients = [];
          loading(false);
        });
    }, 350),
  },
};
</script>

<style lang="scss" scoped>
.modal__content {
    overflow-y: visible !important;
}
</style>