export default {
    CONSULTATION: {
        label: "Consultation",
        badgeColorClass: "light-info",
        colorClass: "info",
        icon: "bi-eyeglasses",
    },
    CONSULTATION_OPERATION: {
        label: "Consultation Operation",
        badgeColorClass: "light-info",
        colorClass: "info",
        icon: "bi-scissors",
    },
    CONSULTATION_RETOUCH: {
        label: "Consultation Retouch",
        badgeColorClass: "light-info",
        colorClass: "info",
        icon: "bi-eyedropper",
    },
    CONSULTATION_SIMULATION: {
        label: "Consultation Simulation",
        badgeColorClass: "light-info",
        colorClass: "info",
        icon: "bi-eyedropper",
    },
    OPERATION: {
        label: "Operation",
        badgeColorClass: "light-danger",
        colorClass: "danger",
        icon: "bi-scissors",
    },
    RETOUCH: {
        label: "Retouch",
        badgeColorClass: "light-success",
        colorClass: "success",
        icon: "bi-eyedropper",
    },
};